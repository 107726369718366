export enum BackendMethod {
  GET = 'get',
  POST = 'post',
  POST_UNAUTH = 'postUnauth',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export interface BackendParams<T> {
  url: string;
  data?: T;
  method: BackendMethod;
}

export type CreateBackendParam<T> = (dto: T) => BackendParams<T>;
