import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { APP_VERSION, IS_ADMIN } from '@memberspot/frontend/shared/util/tokens';
import { BackendMethod, BackendParams } from '@memberspot/shared/model/backend';
import { EMPTY, Observable, tap, throwError, timer } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  retryWhen,
  switchMap,
} from 'rxjs/operators';

export interface DataUrlObject {
  url: string;
  data: any;
}

export const genericRetryStrategy =
  (
    started: number,
    {
      maxRetryAttempts = 4,
      scalingDuration = 1000,
      includedStatusCodes = [0, 502, 504],
    }: {
      maxRetryAttempts?: number;
      scalingDuration?: number;
      includedStatusCodes?: number[];
    } = {},
  ) =>
  (attempts: Observable<any>) => {
    let reqTime: number;

    return attempts.pipe(
      tap(() => {
        if (!reqTime) {
          reqTime = new Date().valueOf() - started;
        }
      }),
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          includedStatusCodes.findIndex((e) => e === error.status) < 0
        ) {
          error.reqTime = reqTime;

          return throwError(() => error);
        }

        console.log(
          `Attempt ${retryAttempt}: retrying in ${
            retryAttempt * scalingDuration
          }ms`,
        );

        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      }),
      // finalize(() => console.log('We are done!'))
    );
  };

export const BACKEND_URL = new InjectionToken<string>('backend url');

@Injectable({
  providedIn: 'root',
})
export class HttpBackendService {
  private _afAuth = inject(Auth);
  private _isAdmin = inject(IS_ADMIN);
  private _idToken$ = idToken(this._afAuth).pipe(
    filter((t): t is string => !!t),
  );

  private _http = inject(HttpClient);
  private _url = inject(BACKEND_URL);
  private _appVersion = inject(APP_VERSION);

  generic<R = any, T extends object | undefined = any>(
    action: BackendParams<T>,
  ) {
    switch (action.method) {
      case BackendMethod.GET:
        return this.get<R>(action.url);

      case BackendMethod.POST:
        return this.post<R>(action.url, action?.data);

      case BackendMethod.PATCH:
        return this.patch<R>(action.url, action.data);

      case BackendMethod.PUT:
        return this.put<R>(action.url, action.data);

      case BackendMethod.DELETE:
        return this.delete<R>(action.url);

      case BackendMethod.POST_UNAUTH:
        return this.postUnAuth<R>(action.url, action.data);

      default:
        return EMPTY;
    }
  }

  get<T = any>(url: string, host?: string) {
    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.get<T>((host || this._url) + url, { headers }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  post<T = any>(obj: DataUrlObject): Observable<T>;
  post<T = any>(url: string, data?: any): Observable<T>;
  post<T = any>(
    urlOrObj: string | DataUrlObject,
    dataOrEmpty?: any,
  ): Observable<T> {
    let data: any | undefined;
    let url: string;

    if (typeof urlOrObj === 'string') {
      url = urlOrObj;
      data = dataOrEmpty;
    } else {
      url = urlOrObj.url;
      data = urlOrObj.data;
    }

    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.post<T>(this._url + url, data, {
          headers,
        }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  postCustomBackend<T = any>(backend: string, url: string, data = {}) {
    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.post<T>(backend + url, data, {
          headers,
        }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  postUnAuth<T = any>(url: string, data = {}) {
    const started = new Date().valueOf();

    return this._http
      .post<T>(this._url + url, data)
      .pipe(retryWhen(genericRetryStrategy(started)));
  }

  patch<T = any>(url: string, data = {}) {
    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.patch<T>(this._url + url, data, {
          headers,
        }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  put<T = any>(url: string, data = {}) {
    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.put<T>(this._url + url, data, {
          headers,
        }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  delete<T = any>(url: string) {
    const started = new Date().valueOf();

    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this._http.delete<T>(this._url + url, {
          headers,
        }),
      ),
      retryWhen(genericRetryStrategy(started)),
    );
  }

  getAuthHeaders() {
    return this._idToken$.pipe(
      first(),
      map((t) => this._createAuthorizationHeaderInput(t)),
    );
  }

  private _createAuthorizationHeaderInput(token: string) {
    const headers = new HttpHeaders()
      // .set('Content-Type', 'application/json')
      .set('authorization', token)
      .set('app-version', this._appVersion)
      .set('app', this._isAdmin ? 'admin' : 'client');

    return headers;
  }
}
